<template>
  <div id="q-app">
    <router-view />
  </div>
</template>

<script >
export default {
  name: 'App'
}
</script>
<style lang="css">
  @import "./assets/css/custom-style.css";
</style>