
export const AppMenus = {
    
	navbarTopRightItems: [],
	navbarTopLeftItems: [],
	navbarSideLeftItems: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/accounts",
    "label": "Accounts",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/records",
    "label": "Records",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/rekords",
    "label": "Rekords",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/user",
    "label": "User",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/helpme",
    "label": "Helpme",
    "icon": "extension",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	AccountsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Firstname",
    "align": "left",
    "sortable": false,
    "name": "firstname",
    "field": "firstname"
  },
  {
    "label": "Lastname",
    "align": "left",
    "sortable": false,
    "name": "lastname",
    "field": "lastname"
  },
  {
    "label": "Info",
    "align": "left",
    "sortable": false,
    "name": "info",
    "field": "info"
  },
  {
    "label": "Date",
    "align": "left",
    "sortable": false,
    "name": "date",
    "field": "date"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	firstnameItems: [    

    ],
	RecordsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Productname",
    "align": "left",
    "sortable": false,
    "name": "productname",
    "field": "productname"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Category",
    "align": "left",
    "sortable": false,
    "name": "category",
    "field": "category"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	RekordsTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Age",
    "align": "left",
    "sortable": false,
    "name": "age",
    "field": "age"
  },
  {
    "label": "Sex",
    "align": "left",
    "sortable": false,
    "name": "sex",
    "field": "sex"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	sexItems: [    
{value: "Male", label: "Male"},
	{value: "Female", label: "Female"}
    ],
	UserTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Pid",
    "align": "left",
    "sortable": false,
    "name": "pid",
    "field": "pid"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	HelpmeTableHeaderItems: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Tax",
    "align": "left",
    "sortable": false,
    "name": "tax",
    "field": "tax"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Action",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: '',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}