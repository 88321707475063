var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-editor',{ref:"editor",attrs:{"dense":_vm.$q.screen.lt.md,"definitions":{
            upload: {
                tip: 'Insert image',
                icon: 'photo',
                handler: _vm.showImageDialog,
            },
        },"toolbar":[
            ['left', 'center', 'right', 'justify'],
            [
                'bold',
                'italic',
                'strike',
                'underline',
                'subscript',
                'superscript' ],
            ['hr', 'link', 'upload'],
            [
                {
                    icon: _vm.$q.iconSet.editor.fontSize,
                    fixedLabel: true,
                    fixedIcon: true,
                    list: 'no-icons',
                    options: [
                        'size-1',
                        'size-2',
                        'size-3',
                        'size-4',
                        'size-5',
                        'size-6',
                        'size-7' ],
                },
                'removeFormat' ],
            ['quote', 'unordered', 'ordered'],

            ['undo', 'redo'],
            ['viewsource'] ],"fonts":{
            arial: 'Arial',
            arial_black: 'Arial Black',
            comic_sans: 'Comic Sans MS',
            courier_new: 'Courier New',
            impact: 'Impact',
            lucida_grande: 'Lucida Grande',
            times_new_roman: 'Times New Roman',
            verdana: 'Verdana',
        }},on:{"input":_vm.update},model:{value:(_vm.qeditor),callback:function ($$v) {_vm.qeditor=$$v},expression:"qeditor"}}),_c('q-dialog',{model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c('q-card',{staticStyle:{"width":"500px","max-width":"80vw"}},[_c('q-card-section',{staticClass:"row items-center q-pb-none"},[_c('div',{staticClass:"text-h6"},[_vm._v("Insert Image")]),_c('q-space'),_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"icon":"close","flat":"","round":"","dense":""}})],1),_c('q-card-section',[_c('div',{staticClass:"q-mb-md"},[_c('q-input',{ref:"imageUrl",attrs:{"outlined":"","dense":"","placeholder":"Enter Image Url","label":"Image Url"},model:{value:(_vm.imageUrl),callback:function ($$v) {_vm.imageUrl=$$v},expression:"imageUrl"}})],1),_c('div',{staticClass:"row q-col-gutter-md"},[_c('div',{staticClass:"col"},[_c('q-input',{attrs:{"outlined":"","dense":"","placeholder":"Width","label":"Width"},model:{value:(_vm.imageWidth),callback:function ($$v) {_vm.imageWidth=$$v},expression:"imageWidth"}})],1),_c('div',{staticClass:"col"},[_c('q-input',{attrs:{"outlined":"","dense":"","placeholder":"Height","label":"Height"},model:{value:(_vm.imageHeight),callback:function ($$v) {_vm.imageHeight=$$v},expression:"imageHeight"}})],1)])]),_c('q-card-actions',{attrs:{"align":"center"}},[_c('q-btn',{attrs:{"color":"primary","flat":"","label":"Insert Image"},on:{"click":_vm.insertImage}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }