
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter)
let routes = [
	{
		name: 'main',
		path: '/',
		component: () => import('layouts/MainLayout.vue'),
		children: [
			
			{ 
				path: '/(home)?', 
				name: 'home' , 
				component: () => import('pages/home/home.vue'),
				props: true
			},
			//Dashboard routes


//accounts routes
			{ 
				path: '/accounts/', 
				name: 'accountslist', 
				component: () => import('pages/accounts/list.vue'), 
				props: true
			},
			{ 
				path: '/accounts/(list|index)/:fieldName?/:fieldValue?', 
				name: 'accountslistfilter', 
				component: () => import('pages/accounts/list.vue'), 
				props: true
			},
	
			{ 
				path: '/accounts/view/:id', 
				name: 'accountsview', 
				component: () => import('pages/accounts/view.vue'), 
				props: true
			},
	
			{ 
				path: '/accounts/add', 
				name: 'accountsadd', 
				component: () => import('pages/accounts/add.vue'), 
				props: true
			},
	
			{ 
				path: '/accounts/edit/:id', 
				name: 'accountsedit', 
				component: () => import('pages/accounts/edit.vue'), 
				props: true
			},
		

//records routes
			{ 
				path: '/records/', 
				name: 'recordslist', 
				component: () => import('pages/records/list.vue'), 
				props: true
			},
			{ 
				path: '/records/(list|index)/:fieldName?/:fieldValue?', 
				name: 'recordslistfilter', 
				component: () => import('pages/records/list.vue'), 
				props: true
			},
	
			{ 
				path: '/records/view/:id', 
				name: 'recordsview', 
				component: () => import('pages/records/view.vue'), 
				props: true
			},
	
			{ 
				path: '/records/add', 
				name: 'recordsadd', 
				component: () => import('pages/records/add.vue'), 
				props: true
			},
	
			{ 
				path: '/records/edit/:id', 
				name: 'recordsedit', 
				component: () => import('pages/records/edit.vue'), 
				props: true
			},
		

//rekords routes
			{ 
				path: '/rekords/', 
				name: 'rekordslist', 
				component: () => import('pages/rekords/list.vue'), 
				props: true
			},
			{ 
				path: '/rekords/(list|index)/:fieldName?/:fieldValue?', 
				name: 'rekordslistfilter', 
				component: () => import('pages/rekords/list.vue'), 
				props: true
			},
	
			{ 
				path: '/rekords/view/:id', 
				name: 'rekordsview', 
				component: () => import('pages/rekords/view.vue'), 
				props: true
			},
	
			{ 
				path: '/rekords/add', 
				name: 'rekordsadd', 
				component: () => import('pages/rekords/add.vue'), 
				props: true
			},
	
			{ 
				path: '/rekords/edit/:id', 
				name: 'rekordsedit', 
				component: () => import('pages/rekords/edit.vue'), 
				props: true
			},
		

//user routes
			{ 
				path: '/user/', 
				name: 'userlist', 
				component: () => import('pages/user/list.vue'), 
				props: true
			},
			{ 
				path: '/user/(list|index)/:fieldName?/:fieldValue?', 
				name: 'userlistfilter', 
				component: () => import('pages/user/list.vue'), 
				props: true
			},
	
			{ 
				path: '/user/view/:id', 
				name: 'userview', 
				component: () => import('pages/user/view.vue'), 
				props: true
			},
	
			{ 
				path: '/user/add', 
				name: 'useradd', 
				component: () => import('pages/user/add.vue'), 
				props: true
			},
	
			{ 
				path: '/user/edit/:id', 
				name: 'useredit', 
				component: () => import('pages/user/edit.vue'), 
				props: true
			},
		

//helpme routes
			{ 
				path: '/helpme/', 
				name: 'helpmelist', 
				component: () => import('pages/helpme/list.vue'), 
				props: true
			},
			{ 
				path: '/helpme/(list|index)/:fieldName?/:fieldValue?', 
				name: 'helpmelistfilter', 
				component: () => import('pages/helpme/list.vue'), 
				props: true
			},
	
			{ 
				path: '/helpme/view/:id', 
				name: 'helpmeview', 
				component: () => import('pages/helpme/view.vue'), 
				props: true
			},
	
			{ 
				path: '/helpme/add', 
				name: 'helpmeadd', 
				component: () => import('pages/helpme/add.vue'), 
				props: true
			},
	
			{ 
				path: '/helpme/edit/:id', 
				name: 'helpmeedit', 
				component: () => import('pages/helpme/edit.vue'), 
				props: true
			},
		

			
			
			{ path: '/error/forbidden', component: () => import('pages/errors/forbidden.vue') },
			{ path: '/error/notfound', component: () => import('pages/errors/pagenotfound.vue') }
		]
	},
];


/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default async function ({ store, ssrContext }) {
	let mainRoute = routes.find(x => x.name = "main");

	

	// Always leave this as last one
	if (process.env.MODE !== 'ssr') {
		mainRoute.children.push({path: '*', component: () => import('pages/errors/pagenotfound.vue')});
	}

	const Router = new VueRouter({
		scrollBehavior: () => ({ x: 0, y: 0 }),
		routes,
		// Leave these as they are and change in quasar.conf.js instead!
		// quasar.conf.js -> build -> vueRouterMode
		// quasar.conf.js -> build -> publicPath
		mode: process.env.VUE_ROUTER_MODE,
		base: process.env.VUE_ROUTER_BASE
	});
	return Router
}
