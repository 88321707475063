import Vue from 'vue'
import Vuex from 'vuex'
import { pageComponents } from "./page_components.js"
import { accounts } from "./accounts.js"
import { records } from "./records.js"
import { rekords } from "./rekords.js"
import { user } from "./user.js"
import { helpme } from "./helpme.js"
Vue.use(Vuex)
export default function (/* { ssrContext } */) {
	const Store = new Vuex.Store({
		modules: {
			pageComponents,
			accounts,
		records,
		rekords,
		user,
		helpme
		},
		// enable strict mode (adds overhead!)
		// for dev mode only
		strict: process.env.DEV
	})
	return Store
}